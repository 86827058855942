<template>
  <div class="wrapp-address-tile">
    <template v-if="addressExist">
      <div class="cl-black mb-3">
        {{address.firstname}} {{address.lastname}}<br>
        {{addressJoinSteet}}<br>
        {{address.city}}, {{address.postcode}}, {{address.country_id}}<br>
        <span v-if="address.telephone">T: {{address.telephone}}</span>
      </div>
    </template>
  </div>
</template>

<script>
// import { toast } from '@/modules/notifications'
export default {
  props: {
    address: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  computed: {
    addressExist () {
      if (Object.keys(this.address).length) {
        return true
      }
      // toast.error(this.$t('amazon.checkout.cards.address-not-conform')) hide error message in amazon checkout
      return false
    },
    addressJoinSteet () {
      return (this.address && this.address.street) ? this.address.street.filter(i => i).join(', ') : ''
    }
  }
}
</script>
