<template>
  <div class="payment-method">
    <absolute-loader :showLoader="showLoader" />
    <div class="wrapp-address wrapp-billing">
      <h3> {{ $t('amazon.checkout.cards.billing-address') }}</h3>
      <address-tile :address="billingAddress"></address-tile>
    </div>
    <div class="wrapp-payment-method">
      <h3> {{ $t('amazon.checkout.cards.payment-card') }}</h3>
      <p class="text">{{ paymentDescr }}</p>
    </div>
    <div class="wrapp-edit">
      <button class="btn btn-none bcm-link bcm-link-classic" id="editPayment">{{ $t('amazon.checkout.cards.edit') }}</button>
    </div>
  </div>
</template>

<script>
import AddressTile from 'plugins/Amazon/components/AddressTile'
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'

export default {
  name: 'PaymentDescriptor',
  props: {
    sessionId: {
      type: String,
      required: true
    },
    billingIsValid: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      billingAddress: {},
      paymentDescr: '',
      showLoader: false
    }
  },
  computed: {
    isBillingValid: {
      get () {
        return this.billingIsValid
      },
      set (newValue) {
        this.$emit('update:billingIsValid', newValue)
      }
    }
  },
  methods: {
    goBackToCart () {
      return this.$router.push({ name: 'cart' })
    },
    getPaymentMethod () {
      const _this = this
      return this.$store.dispatch('pl_amazon/getPaymentDescriptor', { sessionId: this.sessionId })
        .then((res) => {
          _this.paymentDescr = res
        })
    },
    getBillingAddress () {
      return this.$store.dispatch('pl_amazon/getBillingAddress', { sessionId: this.sessionId })
        .then((res) => {
          if (!res) {
            this.isBillingValid = true
            this.billingAddress = res
          }
        })
        .catch(() => {
          return this.$store.dispatch('pl_amazon/getShippingAddress', { sessionId: this.sessionId })
            .then((res) => {
              if (!res) {
                this.isBillingValid = false
              } else {
                this.isBillingValid = true
                this.billingAddress = res
              }
            })
            .catch((error) => {
              let { address } = error
              this.isShippingValid = false
              this.shippingAddress = address
            })
            .finally(() => {
              this.enableEdit()
            })
        })
    },
    enableEdit () {
      global.amazon.Pay.bindChangeAction('#editPayment', {
        amazonCheckoutSessionId: this.sessionId,
        changeAction: 'changePayment'
      })
    },
    init () {
      // get payment method from sessionId
      this.showLoader = true
      return Promise.all([this.getPaymentMethod(), this.getBillingAddress()])
        .finally(() => {
          this.enableEdit()
          this.showLoader = false
        })
    }
  },
  mounted () {
    if (process.client) {
      this.init()
    }
  },
  components: {
    AddressTile,
    AbsoluteLoader
  }
}
</script>
