<template>
  <div class="addresses position-relative">
    <div class="wrapp-address wrapp-billing">
      <h3>{{ $t('amazon.checkout.cards.shipping-address') }}</h3>
      <address-tile :address="shippingAddress"></address-tile>
    </div>
    <div class="wrapp-edit">
      <button class="btn btn-none bcm-link bcm-link-classic" id="editBilling">{{ $t('amazon.checkout.cards.edit') }}</button>
    </div>
    <absolute-loader :showLoader="showLoader" />
  </div>
</template>

<script>
import { axiosErrorMessage } from '@/helpers/utils'
// import { toast } from '@/modules/notifications'
import Logger from '@/services/Logger'
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'
import AddressTile from 'plugins/Amazon/components/AddressTile'

export default {
  name: 'Addresses',
  props: {
    sessionId: {
      type: String,
      required: true
    },
    shippingIsValid: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      shippingAddress: {},
      showLoader: false
    }
  },
  computed: {
    isShippingValid: {
      get () {
        return this.shippingIsValid
      },
      set (newValue) {
        this.$emit('update:isShippingValid', newValue)
      }
    }
  },
  methods: {
    goBackToCart () {
      return this.$router.push({ name: 'cart' })
    },
    getShippingAddress () {
      return this.$store.dispatch('pl_amazon/getShippingAddress', { sessionId: this.sessionId })
        .then((res) => {
          if (!res) {
            this.isShippingValid = false
          } else {
            this.isShippingValid = true
            this.shippingAddress = res
          }
        })
        .catch((error) => {
          let { address } = error
          this.isShippingValid = false
          this.shippingAddress = address
          Logger.error('axiosErrorMessage(error)', axiosErrorMessage(error))
          // toast.error(axiosErrorMessage(error))
        })
        .finally(() => {
          this.enableEdit()
        })
    },
    init () {
      // get payment method from sessionId
      this.getShippingAddress()
    },
    enableEdit () {
      global.amazon.Pay.bindChangeAction('#editBilling', {
        amazonCheckoutSessionId: this.sessionId,
        changeAction: 'changeAddress'
      })
    }
  },
  mounted () {
    if (process.client) {
      this.init()
    }
  },
  components: {
    AbsoluteLoader,
    AddressTile
  }
}
</script>
